<template>

  <div class="main">
    <div class="titulo">Presentaciones</div>

    <v-container>

      <v-row>
        <v-col cols="12" xl="3" sm="3">
          <label for="cuentaFilter">Nro.Cuenta: </label>
          <input type="number" id="cuentatFilter" v-model="cuentaFilter" @input="applyFilter" min=0 max=99999999>
        </v-col>
        <v-col cols="12" xl="3" sm="3">
          <label for="cuitFilter">CUIT: </label>
          <input type="number" id="cuitFilter" v-model="cuitFilter" @input="applyFilter" min=0 max=99999999999>
        </v-col>
        <v-col cols="12" xl="3" sm="3">
          <label for="periodoFilter">Período: </label>
          <select id="periodoFilter" v-model="periodoFilter" @change="applyFilter">
            <option value="-1">TODOS</option>
            <option v-for="(periodo, index) in periodos" :key="index" :value="periodo">{{ periodo }}</option>
          </select>
        </v-col>
        <v-col cols="12" xl="3" sm="3">
          <label for="presentacionFilter">Presentación: </label>
          <select id="presentacionFilter" v-model="presentacionFilter" @change="applyFilter">
            <option value="-1">TODAS</option>
            <option value="0">ORIGINAL</option>
            <option value="1">RECTIFICATIVA</option>
          </select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" xl="3" sm="3">
          <label for="estadoFilter">  Estado: </label>
          <select id="estadoFilter" v-model="estadoFilter" @change="applyFilter">
            <option value="">TODOS</option>
            <option value="Borrador">BORRADOR</option>
            <option value="Presentada">FINALIZADO</option>
          </select>
        </v-col>
        <v-col cols="12" xl="4" sm="4">
          <label for=""> Fecha: </label>
          <input type="date" id="fechaPresentacionDesde" v-model="fechaPresentacionDesdeFilter">&nbsp;
          <input type="date" id="fechaPresentacionHasta" v-model="fechaPresentacionHastaFilter">
        </v-col>
        <v-col cols="12" xl="4" sm="4">
          <label for=""> Ingresos: </label>
          <input type="number" id="totalIngresosDesde" min=0 max=9999999 v-model="totalIngresosDesdeFilter">&nbsp;
          <input type="number" id="totalIngresosHasta" min=0 max=9999999 v-model="totalIngresosHastaFilter">
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" xl="3" sm="3">
          <label for="motivoFilter">Motivo: </label>
          <select id="motivoFilter" v-model="motivoFilter" @change="applyFilter">
            <option value="">TODOS</option>
            <option value="POR_BAJA">POR BAJA</option>
            <option value="POR_TRANSFERENCIA">POR TRANSFERENCIA</option>
          </select>
        </v-col>
      </v-row>

      <div>
        <button class="boton" @click="applyFilter">Buscar</button>
        <button class="boton" @click="clearFilters">Limpiar</button>
        <button class="boton" @click="exportCSV">Exportar CSV</button>
        Cantidad registros {{ filteredData.length }}
      </div>

    </v-container>

    <div class="scroll-container">
      <table class="new-grilla">
        <tr class="header-grilla">
          <td class="header-cell">Nro.Cuenta</td>
          <td class="header-cell">CUIT</td>
          <td class="header-cell">Razón Social</td>
          <td class="header-cell">Período</td>
          <td class="header-cell">Presentación</td>
          <td class="header-cell">Motivo</td>
          <td class="header-cell">Estado</td>
          <td class="header-cell">Ingresos</td>
          <td class="header-cell">Fecha de Presentación</td>
          <!-- <td class="header-cell">Importe total Tasa Determinada</td> -->
          <td class="header-cell"></td>
        </tr>

        <tr v-for="(d, i) in filteredData" :key="i">
          <td>{{ data[i].cuenta }}</td>
          <td>{{ data[i].cuit }}</td>
          <td>{{ data[i].razon_social }}</td>
          <td>{{ data[i].periodo }}</td>
          <td>{{ data[i].presentacionD }}</td>
          <td>{{ data[i].motivo }}</td>
          <td>{{ data[i].estado }}</td>
          <td>{{ data[i].ingresos }}</td>
          <td>{{ formatDate(data[i].fechaCierre) }}</td>
          <!-- <td></td> -->
          <td>
            <button class="boton-verddjj" @click="ver(data[i])">Ver Presentación</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, defineProps, defineEmits } from "vue";
import axios from 'axios';
import { url } from '../config.js';

const props = defineProps({
  usuario: {
    type: Object,
    required: true,
  },
  token: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['onVerDDJJ']);

const loading = ref(false);
const error = ref("");
const data = ref([]);
const filteredData = ref([]);

const cuentaFilter = ref('');
const cuitFilter = ref('');

const currentYear = new Date().getFullYear();
const periodos = Array.from({length: currentYear - 2018}, (_, index) => (currentYear - index).toString());
const periodoFilter = ref('-1');
const presentacionFilter = ref('-1');
const estadoFilter = ref('Presentada');
const fechaPresentacionDesdeFilter = ref('');
const fechaPresentacionHastaFilter = ref('');
const totalIngresosDesdeFilter = ref('');
const totalIngresosHastaFilter = ref('');
const motivoFilter = ref('');

function saveState() {
  const state = {
    cuentaFilter: cuentaFilter.value,
    cuitFilter: cuitFilter.value,
    periodoFilter: periodoFilter.value,
    presentacionFilter: presentacionFilter.value,
    estadoFilter: estadoFilter.value,
    fechaPresentacionDesdeFilter: fechaPresentacionDesdeFilter.value,
    fechaPresentacionHastaFilter: fechaPresentacionHastaFilter.value,
    totalIngresosDesdeFilter: totalIngresosDesdeFilter.value,
    totalIngresosHastaFilter: totalIngresosHastaFilter.value,
    motivoFilter: motivoFilter.value,
    data: data.value
  };
  localStorage.setItem('savedState', JSON.stringify(state));
}

function restoreState() {
  const savedState = localStorage.getItem('savedState');
  if (savedState) {
    const state = JSON.parse(savedState);
    cuentaFilter.value = state.cuentaFilter || '';
    cuitFilter.value = state.cuitFilter || '';
    periodoFilter.value = state.periodoFilter || '-1';
    presentacionFilter.value = state.presentacionFilter || '-1';
    estadoFilter.value = state.estadoFilter || 'Presentada';
    fechaPresentacionDesdeFilter.value = state.fechaPresentacionDesdeFilter || '';
    fechaPresentacionHastaFilter.value = state.fechaPresentacionHastaFilter || '';
    totalIngresosDesdeFilter.value = state.totalIngresosDesdeFilter || '';
    totalIngresosHastaFilter.value = state.totalIngresosHastaFilter || '';
    motivoFilter.value = state.motivoFilter || '';
    data.value = state.data || [];
    filteredData.value = state.data || [];
  } else {
    applyFilter();
  }
}

onMounted(async () => {
  restoreState();
  applyFilter();
});

async function applyFilter() {

  loading.value = true;
  error.value = '';
  try {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + props.token
    };
    
    const payload = { cuenta: cuentaFilter.value, cuit: cuitFilter.value, periodo: periodoFilter.value, presentacion: presentacionFilter.value, estado: estadoFilter.value, fechaD: fechaPresentacionDesdeFilter.value, fechaH: fechaPresentacionHastaFilter.value, montoD: totalIngresosDesdeFilter.value, montoH: totalIngresosHastaFilter.value, motivo: motivoFilter.value };
    let res = await axios.post(url + '/ddjj/leerPresentacionesAll', payload, {headers: headers});

    data.value = res.data;
    filteredData.value = res.data;
    saveState();
    loading.value = false;
  } catch (e) {
    loading.value = false;
    error.value = 'Error: ' + e;
  }
}

function clearFilters() {
  cuentaFilter.value = '';
  cuitFilter.value = '';
  periodoFilter.value = '-1';
  presentacionFilter.value = '-1';
  estadoFilter.value = 'Presentada';
  fechaPresentacionDesdeFilter.value = '';
  fechaPresentacionHastaFilter.value = '';
  totalIngresosDesdeFilter.value = '';
  totalIngresosHastaFilter.value = '';
  motivoFilter.value = ''; 
  localStorage.removeItem('savedState');
  applyFilter();
}


function ver(ddjjRow) {
  emit('onVerDDJJ', ddjjRow);
}

function formatDate(dateString) {
  if (dateString == null) return '';
  const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  return new Date(dateString).toLocaleDateString('es-ES', options);
}

function exportCSV() {
  const csvContent = generateCSV();
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  
  const a = document.createElement('a');
  a.href = url;
  a.download = 'presentaciones.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

function generateCSV() {
  const headers = ['NRO.CUENTA', 'CUIT', 'RAZON SOCIAL', 'PERIODO', 'PRESENTACION', 'ESTADO', 'INGRESOS', 'FECHA DE PRESENTACION', 'HORA DE PRESENTACION'];
  const rows = [headers.join(',')];
  for (const item of filteredData.value) {
    const row = [
      item.cuenta,
      item.cuit,
      item.razon_social,
      item.periodo,
      item.presentacionD,
      item.estado,
      item.ingresos,
      formatDate(item.fechaCierre)
    ];
    rows.push(row.join(','));
  }
  return rows.join('\n');
}

</script>

<style>
.main {
  color: white;
  padding: 10px;
}
.titulo {
  font-size: x-large;
  color: white;
  margin-top: 8px;
}

.boton-verddjj {
  border: 2px solid #000000;
  padding: 8px 16px;
  width: 170px;
  background-color: white;
  color: black !important;
}

.scroll-container {
  max-height: 100%;
  overflow-y: auto;
}

.header-grilla {
  background-color: lightgrey;
  color: black;
  height: 50px;
}
.header-cell {
  white-space: pre-wrap;
  width: 100px;
  text-align: center;
}
.filter-container {
  margin-bottom: 10px;
}
.new-grilla {
  width: 95%;
}
button, input, select, textarea {
  background-color: white !important; 
}
</style>

